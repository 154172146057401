import { Box, Chip, Divider, Grid, Paper, Stack, styled, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ticketActions } from 'redux/slices/ticketSlice'
import ListingTabType from '../constants/tab-type'
import CounterReload from './CounterReload'
import ListingFilterTab from './ListingFilterTab'
import ListingTableToolbar from './ListingTableToolbar'
import ModalCreateTicket from './TicketCreateFormDrawer'
import ModalExportTicket from './TicketExportForm'
import { formatDate } from 'utils/formater'
import CloseIcon from '@mui/icons-material/Close'
import { layoutActions } from 'redux/slices/layoutSlice'

const StyledDivider = styled(Divider)(() => ({
  height: '0.5px',
  borderBottom: '1px solid rgba(224, 224, 224, 1)',
}))

function TicketListingMain({ currentTab, children, onChangeFilter, onExport = () => { }, initialFilter = {} }) {
  const navigate = useNavigate()
  const theme = useTheme()
  const dispatch = useDispatch()
  const userInfo = useSelector((state) => state.user.user)
  const [filter, setFilter] = useState(initialFilter)

  const handleChangeFilter = (newFilter) => {
    onChangeFilter(newFilter)
  }

  const handleChangeTab = (tab) => {
    dispatch(layoutActions.resetNewTicketItems())

    let defaultFilter = {}

    Object.keys(filter).forEach((key) => {
      if (filter[key] && ['status', 'per_page'].includes(key)) {
        defaultFilter[key] = filter[key]
      }
    })

    const strQuery = '?' + new URLSearchParams(defaultFilter).toString()

    switch (tab) {
      case ListingTabType.MANAGED_BY_ME:
        navigate(`/tickets/toi-quan-ly${strQuery}`)
        break
      case ListingTabType.HANDLE_BY_ME:
        navigate(`/tickets/toi-xu-ly${strQuery}`)
        break
      case ListingTabType.MY_TICKET:
        navigate(`/tickets/cua-toi${strQuery}`)
        break
      case ListingTabType.NEED_APPROVAL:
        navigate(`/tickets/can-phe-duyet${strQuery}`)
        break
      default:
        break
    }
  }

  const handleRemoveFilter = (field) => {
    if (field === 'created_at') {
      filter['created_at[gte]'] = null
      filter['created_at[lte]'] = null
    } else if (field === 'deadline') {
      filter['deadline[gte]'] = null
      filter['deadline[lte]'] = null
    } else if (field === 'status') {
      filter['status'] = -1
    } else {
      filter[field] = null
    }

    handleChangeFilter(filter)
  }

  const renderListFilter = () => {
    let currentFilter = []

    const arr = [
      // { key: 'status', icon: 'Trạng thái', removeKey: 'status', label: (e) => TicketStatusType[e]['text'] },
      { key: 'created_by', icon: 'Nguời tạo:', removeKey: 'created_by', label: (e) => e?.name },
      { key: 'assignee', icon: 'Nguời xử lý:', removeKey: 'assignee', label: (e) => e?.name },
      { key: 'approve_by', icon: 'Nguời phê duyệt:', removeKey: 'approve_by', label: (e) => e?.name },
      { key: 'area', icon: 'Vùng:', removeKey: 'area', label: (e) => e?.name },
      { key: 'category', icon: 'Danh mục:', removeKey: 'category', label: (e) => e?.name },
      { key: 'tag', icon: 'Nhãn:', removeKey: 'tag', label: (e) => e?.name },
      { key: 'store', icon: 'Cửa hàng:', removeKey: 'store', label: (e) => e?.name },
      {
        key: ['created_at[gte]', 'created_at[lte]'],
        icon: 'Thời gian tạo:',
        removeKey: 'created_at',
        label: (e) => `${formatDate(e[0])} - ${formatDate(e[1])}`,
      },
      {
        key: ['deadline[gte]', 'deadline[lte]'],
        icon: 'Thời gian xử lý:',
        removeKey: 'deadline',
        label: (e) => `${formatDate(e[0])} - ${formatDate(e[1])}`,
      },
    ]
      .map((e) => ({ ...e, key: Array.isArray(e.key) ? e.key : [e.key] }))
      .map((e, index) => {
        const values = e.key.map((e) => filter[e])

        if (values.filter((e) => !!e).length == 0) {
          return undefined
        } else {
          if (window.location.pathname.indexOf('/can-phe-duyet') > -1 && e.removeKey === 'approve_by') {
            return undefined
          }

          if (window.location.pathname.indexOf('/toi-xu-ly') > -1 && e.removeKey === 'assignee') {
            return undefined
          }

          return currentFilter.push(
            <Grid key={index} item>
              <Chip
                icon={<b>{e.icon}</b>}
                deleteIcon={<CloseIcon style={{ fontSize: '17px' }} />}
                label={e.label?.(values.length > 1 ? values : values[0])}
                onDelete={() => handleRemoveFilter(e.removeKey)}
                style={{
                  fontSize: '12px',
                  padding: '2px 8px 2px 8px',
                  borderRadius: '4px',
                  background: '#ffff',
                  border: '1px solid #cccc',
                  color: '#737373',
                  fontWeight: '500px',
                }}
              />
            </Grid>
          )
        }
      })

    return (
      <Box style={{ marginBottom: currentFilter.length > 0 ? '14px' : 0 }}>
        <Stack direction={'row'} useFlexGap flexWrap="wrap" spacing={1 / 2}>
          {currentFilter}
        </Stack>
      </Box>
    )
  }

  useEffect(() => {
    setFilter({
      ...initialFilter,
    })
  }, [initialFilter])

  useEffect(() => {
    setFilter({
      ...initialFilter,
      status: filter.status,
    })
  }, [filter.status])

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ mb: 2 }}>
        <Grid
          container
          justifyContent="space-between"
          sx={{
            [theme.breakpoints.down('md')]: {
              gap: '16px',
            },
          }}
        >
          <Grid item md={6} sx={{ display: 'flex' }} alignItems="center">
            <Box>
              <Typography
                fontWeight={700}
                lineHeight="28px"
                sx={{
                  [theme.breakpoints.down('md')]: {
                    fontSize: '16px',
                  },
                  [theme.breakpoints.up('md')]: {
                    fontSize: '1.375rem',
                  },
                }}
              >
                Danh sách yêu cầu
              </Typography>
              <Box sx={{ mr: 1 }}>
                <CounterReload
                  number={60}
                  onReload={() => {
                    dispatch(ticketActions.changeKeyRefetch(Date.now()))
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} sx={{ display: 'flex' }} justifyContent="flex-end" spacing={1}>
            <Stack direction="row" spacing={1}>
              <ModalExportTicket
                filter={filter}
                onExported={() => {
                  // dispatch(ticketActions.changeKeyRefetch(Date.now()))
                }}
              />
              <ModalCreateTicket
                onCreated={() => {
                  // dispatch(ticketActions.changeKeyRefetch(Date.now()))
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Paper sx={{ width: '100%', mb: '14px' }}>
        <Box px={2}>
          <ListingFilterTab value={currentTab} userInfo={userInfo} onChange={(val) => handleChangeTab(val)} />
          <StyledDivider orientation="vertical" />
        </Box>

        <ListingTableToolbar
          tab={currentTab}
          filter={filter}
          onChange={(val) => handleChangeFilter(val)}
          onExport={(val) => onExport(val)}
        />
      </Paper>

      {renderListFilter()}

      {children}
    </Box>
  )
}

export default TicketListingMain
