import _ from 'lodash'
import { v4 as uuid } from 'uuid'

import { HTTP_STATUS } from '../constants/common'
import { layoutActions } from '../redux/slices/layoutSlice'

export const isSuccessRequest = (status) => {
  return [HTTP_STATUS.SUCCESS, HTTP_STATUS.CREATED_SUCCESS].includes(status)
}

export const createMessage = (msg, delay = 5000) => {
  return layoutActions.showMessage({ ...msg, id: uuid(), createdAt: Date.now() }, delay)
}

export const parseURLQuery = (queryString = '') => {
  let strQuery = queryString?.trim()
  if (strQuery.indexOf('?') === 0) {
    strQuery = strQuery.substring(1)
  }

  let params = {}
  if (strQuery) {
    strQuery.split('&').forEach((query) => {
      const [key, value] = query.split('=')
      params[key] = decodeURI(value)
    })
  }

  return params
}

export const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export const htmlDecode = (input) => {
  var doc = new DOMParser().parseFromString(input, 'text/html')
  return doc.documentElement.textContent
}

export const detectMob = () => {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i]

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem)
  })
}

export const transformObjToUrlQuery = (data) => {
  if (data && _.isObject(data)) {
    let query = []

    Object.keys(data).forEach((key) => {
      if (!_.isUndefined(data[key]) && data[key] !== -1 && !_.isNull(data[key])) query.push(`${key}=${data[key]}`)
    })

    return query.length > 0 ? '?' + query.join('&') : ''
  }

  return ''
}

export const truncateString = (str, num) => {
  if (str.length > num) {
    return str.slice(0, num) + '...'
  } else {
    return str
  }
}

export const parseParams = (querystring) => {
  const params = new URLSearchParams(querystring)
  const obj = {}
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      if (params.get(key) !== 'undefined') obj[key] = params.getAll(key)
    } else {
      if (params.get(key) !== 'undefined') obj[key] = params.get(key)
    }
  }

  return obj
}

export const removeUndefinedAttribute = (obj) => {
  const params = {}
  Object.keys(obj).forEach((key) => {
    if (obj[key] || obj[key] === 0 || obj[key] === '') {
      params[key] = obj[key]
    }
    return {}
  })
  return params
}

/**
 * Check if the url is valid
 * @param {string} urlString
 * @returns
 */
export const isValidUrl = (urlString) => {
  let url
  try {
    url = new URL(urlString)
  } catch (e) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const getFileUrl = (urlString) => {
  return isValidUrl(urlString) ? urlString : process.env.REACT_APP_UPLOAD_PATH + urlString
}

export function checkIsLocal() {
  return !!(window.location.hostname.indexOf('127.0.0.1') > -1 || window.location.hostname.indexOf('localhost') > -1)
}
