import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { CircularProgress, Grid, TableSortLabel, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Pagination from '@mui/material/Pagination'
import PaginationItem from '@mui/material/PaginationItem'
import Paper from '@mui/material/Paper'
import Select, { selectClasses } from '@mui/material/Select'
import { useTheme } from '@mui/material/styles'
import TableMui from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { visuallyHidden } from '@mui/utils'
import { mainBgColor } from 'config'
import { isArray, isFunction } from 'lodash'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

/**
 *
 * @typedef {object} IPagination
 * @property {number} page
 * @property {number} rowsPerPage
 * @property {number} count
 * @property {boolean} [isFirst]
 * @property {boolean} [isPrevious]
 * @property {boolean} [isNext]
 * @property {boolean} [isLast]
 * @property {number[]} [rowsPerPageOptions]
 * @property {(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, page: number)=>void} [onPageChange]
 * @property {(event: any) => void} [onRowsPerPageChange]
 * @property {(paginationInfo: {from: number; to: number; count: number; page: number;})=>React.ReactNode} [labelDisplayedRows]
 */
/**
 * @param {IPagination}props
 * @returns {React.ReactNode}
 */
const CustomTablePagination = (props) => {
  const theme = useTheme()

  const {
    page = 0,
    rowsPerPage = 0,
    count = 0,
    isFirst = false,
    isPrevious = true,
    isNext = true,
    isLast = false,
    rowsPerPageOptions = [10, 50, 100],
    hoverRow = true,
    onPageChange: _onPageChange,
    onRowsPerPageChange,
    labelDisplayedRows = ({ count, from, to, page }) =>
      `Hiển thị ${from} – ${to} trên số ${count !== -1 ? count : `more than ${to}`}`,
  } = props || {}

  const totalPage = Math.ceil(count / rowsPerPage)

  const onPageChange = (event, p) => {
    p !== page && _onPageChange && _onPageChange(event, p)
  }

  const onPageSizeChange = (event) => {
    if (onRowsPerPageChange) onRowsPerPageChange(event)
  }

  return (
    totalPage > 0 && (
      <Grid
        id={props.id || 'cus-pagination'}
        container
        justifyContent={'space-between'}
        px={2}
        py={1}
        alignItems={'center'}
        sx={{
          borderTop: `1px solid ${mainBgColor}`,
        }}
      >
        <Grid
          item
          sx={{
            textAlign: { xs: 'center', sm: 'left' },
            order: { xs: 2, sm: 1 },
            my: { xs: 0.5, sm: 0 },
          }}
          xs={12}
          sm={6}
          md={4}
        >
          <Box flexDirection={'row'} display={'flex'} alignItems={'center'}>
            <Typography
              sx={{
                fontSize: '14px',
                [theme.breakpoints.down('md')]: {
                  fontSize: '13px',
                },
              }}
            >
              {'Số hàng trên trang: '}
            </Typography>
            <Box>
              <FormControl variant="standard">
                <Select
                  sx={{
                    ml: 1,
                    pb: 0,
                    '&:before, &:after': {
                      content: 'unset',
                    },
                    [`> .${selectClasses.select}`]: {
                      pb: 0,
                    },
                    height: 28,
                    borderRadius: '4px',
                  }}
                  labelId="page-size"
                  value={rowsPerPage}
                  onChange={onPageSizeChange}
                  size="small"
                >
                  {rowsPerPageOptions.map((e, index) => (
                    <MenuItem key={index} value={e}>
                      {e}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          sx={{
            textAlign: 'center',
            order: { xs: 1, sm: 2 },
          }}
          xs={12}
          sm={6}
          md={4}
        >
          <Typography
            sx={{
              fontSize: '14px',
              [theme.breakpoints.down('md')]: {
                fontSize: '13px',
              },
            }}
            color={'text.secondary'}
          >
            {labelDisplayedRows({
              count,
              page,
              from: page * rowsPerPage + 1,
              to: Math.min(count, (page + 1) * rowsPerPage),
            })}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={4} order={3}>
          <Pagination
            sx={{
              ul: { justifyContent: { sm: 'center', md: 'flex-end' } },
            }}
            siblingCount={0}
            page={page + 1}
            count={totalPage}
            shape="rounded"
            onChange={(event, page) => onPageChange(event, page - 1)}
            showFirstButton={isFirst}
            hidePrevButton={!isPrevious}
            hideNextButton={!isNext}
            showLastButton={isLast}
            renderItem={(item) => (
              <PaginationItem
                slots={{ previous: ArrowLeftIcon, next: ArrowRightIcon }}
                {...item}
                sx={{
                  fontSize: '14px',
                  [theme.breakpoints.down('md')]: {
                    fontSize: '13px',
                  },
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    )
  )
}

/**
 *
 * @template T
 * @typedef {object} IPropsTable
 * @property {T[]} [data]
 *
 * @property {'asc'|'desc'} [order] default asc
 * @property {string} [orderBy]
 * @property {object} [params]
 * @property {number} [pageInit] default = 1
 * @property {number} [minWidth]
 * @property {(params:{page: number, per_page: number})=>{rows: T[], total: number} | Promise<{rows: T[], total: number}[]>} [onFetch]
 * @property {React.ReactNode} [emptyDataComponent]
 * @property {React.ReactNode} [loadingComponent]
 * @property {boolean} [checkbox]
 * @property {number} [height]
 *
 * @property {object[]} columns
 * @property {string} columns.name
 * @property {string} columns.title
 * @property {boolean} columns.sortable
 * @property {'left' | 'center' | 'right' | 'justify' | 'inherit'} [columns.align]
 * @property {(value, row:T, index: number)=>React.ReactNode} [columns.formatter]
 *
 * @property {object} [paginationProps]
 * @property {string} [paginationProps.labelRowsPerPage]
 * @property {boolean} [paginationProps.showFirstButton]
 * @property {boolean} [paginationProps.showLastButton]
 * @property {(paginationInfo: {from: number; to: number; count: number; page: number;})=>React.ReactNode} [paginationProps.labelDisplayedRows]
 * @property {T[]} [data]
 */

/**
 * @template T
 * @param {IPropsTable<T>} props
 */
export const TableTicket = (props) => {
  const { newTicket } = useSelector((state) => state.layout)

  const theme = useTheme()
  const {
    key_reload,
    columns,
    data: _data,
    params: _params,
    pageInit = 1,
    onFetch: _onFetch,
    onPageChange = () => { },
    onPerpageChange = () => { },
    header = true,
    minWidth = 650,
    emptyDataComponent = <Typography variant="h3">{'Không có dữ liệu'}</Typography>,
    loadingComponent = <CircularProgress size={30} />,
    checkbox: isCheckbox = false,
    order = 'asc',
    orderBy,
    per_page,
    height,
    style: _styleTable,
    onNewUpdated = () => { }
  } = props

  const paginationProps = {
    labelRowsPerPage: 'Rows per page:',
    showFirstButton: true,
    showLastButton: true,
    labelDisplayedRows: ({ count, from, to, page }) =>
      `${from}–${to} trong số ${count !== -1 ? count : `more than ${to}`}`,
    ...props.paginationProps,
  }

  const c_per_page = localStorage.getItem('t_per_page')

  const refParams = useRef({
    ..._params,
    page: pageInit,
    per_page: per_page ? per_page : c_per_page ? c_per_page : 50,
    order: order,
    orderBy: orderBy,
  })

  const [state, setState] = useState({
    initialing: true,
    isLoading: true,
    total: 0,
    old_data: [],
    data: [],
    order: order,
    orderBy: orderBy,
    pagination: { ...refParams.current },
  })

  const [heightFooter, setHeightFooter] = useState(0)

  const paginationId = `${props.id || 'table-container'}-pagination`

  useLayoutEffect(() => {
    let paginateDocument = document.getElementById(paginationId)
    if (paginateDocument) setHeightFooter(paginateDocument?.clientHeight)
  })

  useEffect(() => {
    // if (state.initialing) {
    setState({ ...state, isLoading: true })
    const params = (refParams.current = {
      ...refParams.current,
      page: pageInit,
      ..._params,
      order: state.order,
      orderBy: state.orderBy,
    })
    onFetch(params, () => {
      setState({ ...state, isLoading: false })
    })
    // }
  }, [_params, state.order, state.orderBy])

  function mergeArraysIgnoringId(arr1, arr2) {
    const mergedSet = new Set();

    arr1.forEach(obj => mergedSet.add(JSON.stringify({ ...obj })));

    arr2.forEach(obj => mergedSet.add(JSON.stringify({ ...obj })));

    return Array.from(mergedSet).map(str => JSON.parse(str));
  }

  useEffect(() => {
    // if (!state.initialing) {
    //   const params = (refParams.current = {
    //     ...refParams.current,
    //     page: pageInit,
    //     ..._params,
    //     order: state.order,
    //     orderBy: state.orderBy,
    //   })

    //   onFetch(params)
    // }

    let newArr = (newTicket.items || []).map(item => ({
      ...item,
      is_new: true
    }))

    if (state.pagination.page == 1) {
      setState({
        ...state,
        data: [...mergeArraysIgnoringId(newArr, state.data)]
      })
    }
  }, [newTicket.items])

  const onFetch = async (params, cb = () => { }) => {
    // setState({ ...state, isLoading: true })

    const page = params.page - pageInit
    const { total, rows } = _onFetch
      ? await _onFetch(params)
      : {
        rows: (_data || []).slice(page * params.per_page, (page + 1) * params.per_page),
        total: (_data || []).length,
      }

    if (isFunction(cb)) {
      cb()
    }

    setState({
      ...state,
      total,
      data: [...rows],
      isLoading: false,
      initialing: false,
      pagination: {
        ...state.pagination,
        ...params
      }
    })
  }

  const handleOnPageChange = (event, newPage) => {
    onPageChange(newPage)

    refParams.current = { ...refParams.current, page: newPage }
    setState({ ...state, isLoading: true })
    onFetch(refParams.current, () => {
      setState({ ...state, isLoading: false })
    })
  }

  const onRowsPerPageChange = (event) => {
    let per_page = 50
    if (event.target.value) {
      per_page = parseInt(event.target.value)
    }

    localStorage.setItem('t_per_page', per_page)

    onPerpageChange(per_page)

    refParams.current = { ...refParams.current, page: pageInit, per_page }
    setState({ ...state, isLoading: true })

    onFetch(refParams.current, () => {
      setState({ ...state, isLoading: false })
    })
  }

  const onSort = (property) => (event) => {
    const isAsc = refParams.current.orderBy === property && refParams.current.order === 'asc'
    refParams.current = {
      ...refParams.current,
      page: pageInit,
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    }
    setState({
      ...state,
      page: pageInit,
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    })
  }

  const render = () => (
    <Box className={props.className}>
      <TableContainer
        id={props.id || 'table-container'}
        {...(height === undefined
          ? { component: Paper }
          : { sx: { maxHeight: height - heightFooter, position: 'relative' } })}
      >
        <TableMui {...(height !== undefined && { stickyHeader: true })} sx={{ minWidth: minWidth }}>
          {header ? (
            <TableHead>
              <TableRow>
                {isCheckbox && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      inputProps={{
                        'aria-label': 'select all desserts',
                      }}
                    />
                  </TableCell>
                )}
                {columns.map((e, index) =>
                  !state.isLoading && e.sortable === true ? (
                    <TableCell
                      key={e.name || index}
                      align={e.align}
                      sortDirection={state.orderBy === e.name ? state.order : false}
                      style={{
                        whiteSpace: 'nowrap',
                        fontWeight: 'bold',
                        textAlign: e.align,
                      }}
                      sx={{
                        [theme.breakpoints.down('md')]: {
                          fontSize: '13px',
                        },
                      }}
                    >
                      <TableSortLabel
                        active={state.orderBy === e.name}
                        direction={state.orderBy === e.name ? state.order : 'asc'}
                        onClick={onSort(e.name)}
                      // IconComponent={<ArrowDropDownIcon />}
                      >
                        {e.title}
                        <Box
                          component="span"
                          sx={visuallyHidden} /*  display={'flex'} flexDirection={'column'} alignItems={'center'} */
                        >
                          {state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      </TableSortLabel>
                    </TableCell>
                  ) : (
                    <TableCell
                      key={e.name || index}
                      align={e.align}
                      style={{
                        whiteSpace: 'nowrap',
                        fontWeight: 'bold',
                      }}
                      sx={{
                        [theme.breakpoints.down('md')]: {
                          fontSize: '13px',
                        },
                      }}
                    >
                      {e.title}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {
              state.isLoading ? (
                <TableRow>
                  <TableCell colSpan={columns.length + (isCheckbox ? 1 : 0)} align="center">
                    {loadingComponent}
                  </TableCell>
                </TableRow>
              ) : state.data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length + (isCheckbox ? 1 : 0)} align="center">
                    {emptyDataComponent}
                  </TableCell>
                </TableRow>
              ) : (
                state.data.map((row, indexRow) => (
                  <TableRow
                    key={row.id || indexRow}
                    id={`ticket-row-${row.id}`}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      '&:hover': {
                        background: '#FAFAFA',
                      },
                      background: row.is_new ? "rgb(239,68,68,0.1)" : "none"
                    }}
                  >
                    {isCheckbox && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          // checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': indexRow,
                          }}
                        />
                      </TableCell>
                    )}
                    {columns.map((c, index) => {
                      const value =
                        c.name === '' || c.name === undefined
                          ? undefined
                          : c.name.split('.').reduce((d, e) => ([undefined, null].includes(d) ? undefined : d[e]), row)
                      return (
                        <TableCell
                          key={indexRow * columns.length + index}
                          align={c.align}
                          sx={{
                            [theme.breakpoints.down('md')]: {
                              fontSize: '13px',
                            },
                          }}
                        >
                          {row !== undefined
                            ? c.formatter
                              ? c.formatter(
                                value,
                                row,
                                // { ...row, is_new: isNewItem(row.id) ? true : false },
                                indexRow + refParams.current.per_page * (refParams.current.page - pageInit)
                              )
                              : value
                            : undefined}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                ))
              )
            }
          </TableBody>
        </TableMui>
      </TableContainer>

      <CustomTablePagination
        id={paginationId}
        {...paginationProps}
        page={refParams.current.page - pageInit}
        rowsPerPage={refParams.current.per_page}
        count={state.total}
        onPageChange={(e, page) => {
          handleOnPageChange(e, page + 1)
        }}
        onRowsPerPageChange={onRowsPerPageChange}
        rowsPerPageOptions={[10, 50, 100]}
      />

    </Box>
  )

  return height === undefined ? render() : <Paper sx={{ width: '100%', overflow: 'hidden' }}>{render()}</Paper>
}

export { }
