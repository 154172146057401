import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  messages: {},
  newTicket: {
    currentFilter: {
      tab: '',
      status: ''
    },
    old_total: 0,
    old_items: [],
    total: 0,
    items: []
  },
}

const layoutSlice = createSlice({
  initialState,
  name: 'layout',
  reducers: {
    showMessage: (state, action) => {
      const { id, messagePosition, ...other } = action.payload

      return {
        ...state,
        messages: { ...state.messages, [id]: other }
      }
    },
    hideMessage: (state, action) => {
      const newMessages = { ...state.messages }
      if (newMessages[action.payload]) {
        delete newMessages[action.payload]
      }
      return { ...state, messages: newMessages }
    },
    updateNewTicket: (state, action) => {
      return {
        ...state,
        newTicket: {
          ...state.newTicket,
          ...action.payload
        }
      }
    },
    resetNewTicketItems: (state, action) => {
      return {
        ...state,
        newTicket: {
          ...state.newTicket,
          old_items: [],
          items: [],
        }
      }
    },
    resetNewTicket: (state, action) => {
      return {
        ...state,
        newTicket: {
          total: 0,
          items: []
        }
      }
    }
  },
})

export const { actions: layoutActions, reducer: layoutReducer, updateNewTicket, resetNewTicket, resetNewTicketItems } = layoutSlice
