import { delay, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { layoutActions } from '../slices/layoutSlice'

function* showMessage(action) {
  try {
    yield delay(10000)
    yield put(layoutActions.hideMessage(action.payload.id))
  } catch { }
}

function* updateNewTicket(action) {
  try {
    yield delay(20000)
    // yield put(layoutActions.resetNewTicketItems())
  } catch { }
}

export default function* layoutSaga() {
  yield takeEvery(layoutActions.showMessage.type, showMessage)
  yield takeLatest(layoutActions.updateNewTicket.type, updateNewTicket)

}
