import UserRoleLevel from 'constants/user-role-level'
import { isEmpty, isNull, isObject } from 'lodash'
import { transformObjToUrlQuery } from 'utils/common'

export const isSubTicket = (data) => {
  return data.parent && (!isEmpty(data.parent) || !isNull(data.parent))
}

/**
 *
 * @param {number} bytes
 * @param {boolean} [binary]
 * @param {number} [precision]
 * @returns
 */
export const sizeFormat = function (bytes, binary, precision) {
  binary = typeof binary !== 'undefined' ? binary : true
  precision = typeof precision !== 'undefined' ? precision : 2
  var base = binary ? 1024 : 1000
  var prefixes = [
    binary ? 'KiB' : 'kB',
    binary ? 'MiB' : 'MB',
    binary ? 'GiB' : 'GB',
    binary ? 'TiB' : 'TB',
    binary ? 'PiB' : 'PB',
    binary ? 'EiB' : 'EB',
    binary ? 'ZiB' : 'ZB',
    binary ? 'YiB' : 'YB',
  ]
  if (!isFinite(bytes)) {
    return '- Bytes'
  } else if (bytes == 1) {
    return '1 Byte'
  } else if (bytes < base) {
    return bytes + ' Bytes'
  }
  var index = Math.floor(Math.log(bytes) / Math.log(base))
  return (
    parseFloat((bytes / Math.pow(base, Math.floor(index))).toFixed(precision)).toString() + ' ' + prefixes[index - 1]
  )
}

export const formatTicketFilter = (filter) => {
  let newFilter = { ...filter }

  Object.keys(newFilter).forEach((key) => {
    if (isObject(newFilter[key])) {
      newFilter[key] = newFilter[key]?.id
    }
  })

  return newFilter
}

export const isCoordinator = (userInfo) => {
  return userInfo?.role?.level === UserRoleLevel.COORDINATOR || userInfo?.role?.level === UserRoleLevel.SUPERADMIN
}

export const isAreaManager = (userInfo) => {
  return userInfo?.role?.level === UserRoleLevel.AREA_MANAGER || userInfo?.role?.level === UserRoleLevel.SUPERADMIN
}

export const isStoreManager = (userInfo) => {
  return userInfo?.role?.level === UserRoleLevel.STORE_MANAGER || userInfo?.role?.level === UserRoleLevel.SUPERADMIN
}

export const isHandler = (userInfo) => {
  return (
    userInfo?.role?.level === UserRoleLevel.COORDINATOR ||
    (userInfo?.role?.level === UserRoleLevel.EMPLOYEE && userInfo.categories && userInfo.categories.length > 0) ||
    userInfo?.role?.level === UserRoleLevel.SUPERADMIN
  )
}

export const pushQueryState = (filter) => {
  let { origin, pathname } = window.location

  window.history.pushState({}, '', `${origin}${pathname}${transformObjToUrlQuery(formatTicketFilter(filter))}${window.location.hash}`)
}


export const filterNewTicket = (newData, oldData) => {
  if (oldData.length === 0 || newData.length === 0) return []

  console.log(newData, oldData)

  const newTickets = (newData || []).filter(item => {
    const findItem = (oldData || []).find(item2 => item2.id === item.id);
    return !findItem;
  });

  return newTickets;
}

export function updateQueryStringParameter(uri, key, value) {
  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = uri.indexOf('?') !== -1 ? "&" : "?";

  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + "=" + value + '$2');
  } else {
    return uri + separator + key + "=" + value;
  }
}