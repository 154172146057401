import React, { useEffect, useState } from 'react'
import { Paper } from '@mui/material'
import { Box } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux'
import { filterNewTicket, pushQueryState } from '../utils'
import { exportTickets, ticketActions } from 'redux/slices/ticketSlice'
import TicketListingMain from '../components/TicketListingMain'
import ListingTabType from '../constants/tab-type'
import useInitialFilter from 'hooks/useInitialFilter'
import { Image, Text, confirmDialog } from 'components/ui'
import { Table } from 'components/ui/Table'
import * as ticketService from 'services/ticket.service'
import { createMessage } from 'utils/common'
import { MESSAGE_TYPES } from 'constants/common'
import useTicketColumns from 'hooks/useTicketColumns'
import { TableTicket } from 'components/ui/TableTicket'
import { layoutActions } from 'redux/slices/layoutSlice'
import TicketStatusType from 'constants/ticket-status-type'

const TicketManagementByMe = () => {
  const keyRefetch = useSelector((state) => state.ticket.keyRefetch)
  const newTicket = useSelector((state) => state.layout.newTicket)
  const userInfo = useSelector((state) => state.user.user)
  const dispatch = useDispatch()
  const [filter, setFilter] = useState({
    page: 1,
  })

  const [initializing, setInitializing] = useState(true)

  const filterQuery = useInitialFilter()

  const columns = useTicketColumns(userInfo, (id) => ClickDeleteTicket(id))

  useEffect(() => {
    filterQuery.then((res) => {
      setFilter({
        ...filter,
        ...res,
        tab: 'manage',
      })
      setInitializing(false)
    })
  }, [])

  useEffect(() => {
    const params = {
      page: 1,
      per_page: 20,
      order: "asc",
      tab: 'manage',
      status: TicketStatusType.ALL
    }

    const fetchData = async (params) => {
      try {
        const data = await ticketService.getTickets(params).catch((e) => ({ data: [], pagination: { total: 0 } }))

        let new_items = [];

        if (newTicket.old_items.length > 0) {
          new_items = filterNewTicket(data.data, newTicket.old_items)
        }

        let payload = {
          currentFilter: {
            tab: 'manage',
          },
          old_total: data.pagination.total,
          old_items: data.data,
        }

        if (new_items.length > 0) {
          payload = {
            ...payload,
            total: new_items.length,
            items: new_items
          }
        }

        dispatch(layoutActions.updateNewTicket(payload))

      } catch (error) {
        console.log(error)
      }
    }

    fetchData(params)

  }, [filter.tab, keyRefetch])

  const onFetchData = async (params) => {
    pushQueryState(params)

    const data = await ticketService.getTickets(params).catch((e) => ({ data: [], pagination: { total: 0 } }))

    return { rows: data.data, total: data.pagination.total }
  }

  const handleDeleteTicket = async (id) => {
    try {
      await ticketService.deleteTicket(id)
      dispatch(createMessage({ type: MESSAGE_TYPES.SUCCESS, content: 'Xóa yêu cầu thành công' }))
      dispatch(ticketActions.changeKeyRefetch(Date.now()))
    } catch (error) {
      dispatch(createMessage({ type: MESSAGE_TYPES.ERROR, content: 'Xóa yêu cầu không thành công' }))
    }
  }

  const ClickDeleteTicket = async (id) => {
    confirmDialog({
      title: 'Xóa yêu cầu',
      message: 'Bạn có chắc chắn muốn xóa yêu cầu này không ?',
      ok: async () => handleDeleteTicket(id),
      button: { cancel: 'Suy nghĩ lại', ok: 'Đồng ý' },
    })
  }

  return (
    <TicketListingMain
      currentTab={ListingTabType.MANAGED_BY_ME}
      initialFilter={{ ...filter }}
      onChangeFilter={(val) => {
        setFilter({
          ...filter,
          ...val,
          page: 1,
        })
      }}
      onExport={(val) => {
        dispatch(
          exportTickets({
            ...filter,
            ...val,
          })
        )
      }}
    >
      <Paper sx={{ width: '100%', mb: 2 }}>
        {!initializing ? (
          <TableTicket
            key_reload={keyRefetch}
            params={filter}
            columns={columns}
            onPageChange={(page) => {
              setFilter({ ...filter, page: page })
            }}
            onPerpageChange={(perPage) => {
              setFilter({ ...filter, per_page: perPage, page: 1 })
            }}
            onFetch={onFetchData}
            emptyDataComponent={
              <Box sx={{ textAlign: 'center', py: 3 }}>
                <Image src="/assets/images/table-empty.png" width={606} height={341} />
                <Text>{'Không tìm thấy yêu cầu. Xin vui lòng thử lại'}</Text>
              </Box>
            }
            onNewUpdated={(val) => {
              // dispatch(layoutActions.updateNewTicket(val))
            }}
          />
        ) : null}
      </Paper>
    </TicketListingMain>
  )
}

export default TicketManagementByMe
