import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button, ButtonGroup, Chip, Stack, Typography } from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'

CounterReload.propTypes = {
  number: PropTypes.number,
  onReload: PropTypes.func,
}

CounterReload.defaultProps = {
  number: 60, // seconds
  onReload: () => { },
}

function CounterReload({ number, onReload }) {
  const [counter, setCounter] = React.useState(number)

  React.useEffect(() => {
    let timer = null

    if (counter > 0) {
      timer = setInterval(() => setCounter(counter - 1), 1000)
    } else {
      onReload()
      setCounter(number)
    }

    return () => {
      clearInterval(timer)
    }
  }, [counter])

  return (
    <Box>
      <Box variant="outlined">
        <Typography noWrap fontSize="0.75rem">
          {/* Làm mới sau <span style={{ fontWeight: 700 }}>{counter}</span> giây */}
        </Typography>
      </Box>
    </Box>
  )
}

export default CounterReload
